import {LitElement, html, css} from 'lit';
import {styleMap} from 'lit-html/directives/style-map.js';

class InlineMenu extends LitElement {
  static get properties() {
    return {
      inView: {type: Boolean},
      noPadding: {type: Boolean},
      anchorId: {type: String},
    }
  }

  static styles = css`
    .menu {
      position: fixed;
      flex-flow: column;
      bottom: 0;
      left: 50%;
      background: var(--elevation-0);
      width: var(--content-width);
      max-width: 100vw;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      border-radius: 18px 18px 0 0;
      transition: transform 0.3s ease-in-out;
      z-index: 101;
      max-height: 70vh;
    }

    .background {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      transition: opacity 0.3s ease-in-out;
      z-index: 100;
    }

    .menu__bar {
      width: 40px;
      height: 4px;
      background: var(--black-70);
      border-radius: 2px;
      margin: var(--space) auto;
    }
  `;

  render() {
    let position = {
      transform: this.inView ? 'translate(-50%, 0%)' : 'translate(-50%, 101%)',
      padding: this.noPadding ? '0' : 'var(--space)',
      paddingTop: '0'
    };
    let display = {
      display: this.inView ? 'block' : 'none'
    }
    return html`
      <div class="background"
           style="${styleMap(display)}"
           @click="${this.toggle}">
      </div>
      <slot name="trigger" @click="${this.toggle}"></slot>
      <div class="menu" style="${styleMap(position)}">
        <div class="menu__bar" @click="${this.toggle}"></div>
        <slot name="menu-content"></slot>
      </div>
    `;
  }

  toggle() {
    this.inView = !this.inView
    if (this.inView) {
      window.scrollTo(0, this.getScrollPosition())
    }
  }

  getScrollPosition() {
    if (this.anchorId) {
      this.anchorElement = document.getElementById(this.anchorId)
      const space = parseInt(
        getComputedStyle(document.documentElement).getPropertyValue('--space').replace("px", '')
      )
      const anchorPosition = this.anchorElement.getBoundingClientRect().bottom + space
      const menuHeight = this.shadowRoot.querySelector('.menu').getBoundingClientRect().height
      const aboveSheet = window.innerHeight - menuHeight
      return anchorPosition + window.scrollY - aboveSheet
    }
    return window.scrollY
  }
}

customElements.define('inline-menu', InlineMenu);
