import {msg} from '@lit/localize';
import {LitElement, html} from 'lit';
import {createRef, ref} from 'lit/directives/ref.js';

class EventDropIn extends LitElement {

  createRenderRoot() {
    return this;
  }

  static properties = {
    targetUrl: {type: String},
  }

  inputRef = createRef()

  render() {
    return html`
      <div class="event-drop-in">
        <input
          type="text"
          class="event-drop-in__input"
          placeholder="${msg('Whats happening?')}"
          name="title"
          maxLength="80"
          ${ref(this.inputRef)}
          required
        />
        <button type="submit" class="button button--text" style="font-size: 80%" @click="${this.more}">
          ${msg("more")}
        </button>
      </div>
    `;
  }

  more() {
    const encoded = encodeURIComponent(this.inputRef.value.value)
    window.location.href = `${this.targetUrl}?title=${encoded}`
  }
}

customElements.define('event-drop-in', EventDropIn);
